<template>
  <div>
    <label v-if="photo_url == ''" class="mb-0 w-100" :for="labelFor">
      <div class="box__upload--photo" :style="styleName">
        <b-img class="mb-1" :src="require('@/assets/images/icons/Icon-upload.svg')" />
        <h6 class="text-dark fw-bold-800 size14">
          Upload Foto
        </h6>
        <small class="text-dark text-darken-4 size10 fw-bold-400 text-center">Ukuran maksimal foto 256kb</small>
        <input :id="labelFor" type="file" style="opacity:0;height:0" @change="changePhoto">
      </div>
    </label>
    <div v-else class="box__upload--photo" :style="`background-image: url(${photo_url});${styleName}`"
      @click="photo_url = ''">
      <b-avatar class="floating__close--button">
        <feather-icon class="text-danger" icon="XIcon" />
      </b-avatar>
    </div>
  </div>
</template>

<script>
import {
  BImg, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BAvatar,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    styleName: {
      type: String,
      default: 'width: 120px;height: 151px;',
    },
    labelFor: {
      type: String,
    },
    photoUrlParent: {
      type: String,
    },
  },
  watch: {
    photoUrlParent(value) {
      this.photo_url = value
    },
  },
  data() {
    return {
      photo_url: '',
      photo_url_ktp: '',
      photo: null,
    }
  },
  methods: {
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.photo = event.target.files[0]
      this.showPhoto(event.target.files[0])
      const payload = {
        label: this.labelFor,
        photo: this.photo,
      }
      this.$emit('photo', this.photo ? payload : null)
    },
    showPhoto(file) {
      const payload = {
        label: this.labelFor,
        photo_url: this.photo,
      }
      const reader = new FileReader()
      reader.onload = e => {
        this.photo_url = e.target.result
        this.$emit('photo_url', this.photo_url ? payload : null)
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 151px;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
